@import 'npm:@fontsource-variable/inter/index.css';
@import 'npm:swiper/swiper.min.css';
@import 'npm:swiper/modules/navigation/navigation.min.css';

@tailwind base;

@layer base {
  html {
    font-family: 'Inter Variable', sans-serif;
    hyphens: auto;
    letter-spacing: 0.14px;
    @apply body;

    /* Default value variables */
    --elevation-profile-height-small: 0px;
    --elevation-profile-height-medium: 0px;
    --elevation-profile-height-large: 0px;

    --notification-width-zero: calc(
      (100vw - theme('spacing.m-content-zero') * 2)
    );
    --notification-width-xxs: calc(
      (100vw - theme('spacing.m-content-xxs') * 2)
    );
    --notification-width-sm: calc(
      (4 / 8) * (100vw - theme('spacing.m-content-sm') * 2)
    );
    --notification-width-md: calc(
      (4 / 8) * (100vw - theme('spacing.m-content-md') * 2)
    );
    --notification-width-mdxx: calc(
      (4 / 12) * (100vw - theme('spacing.m-content-mdxx') * 2)
    );
    --notification-width-lg: calc(
      (4 / 12) * (100vw - theme('spacing.m-content-lg') * 2)
    );
    --notification-width-xl: calc(
      (4 / 12) * (100vw - theme('spacing.m-content-xl') * 2)
    );
  }

  body {
    overflow: hidden;
  }

  /* Disables browser touch events for the map to avoid pinch-zoom bug on iOS 16 */
  /* the bug: pinching-zoom was scaling the entire page and not zooming the map */
  element-map canvas {
    touch-action: none;
  }

  element-files-dropdown-list [type='checkbox'] {
    @apply border-2 border-black bg-chm-tertiary-gray focus:ring-0 cursor-pointer;
    border-radius: 2px;
  }

  element-files-dropdown-list [type='checkbox']:checked {
    @apply bg-black hover:bg-chm-focus-red;
  }

  element-share-popup [type='checkbox'],
  element-share-or-print-popup [type='checkbox'] {
    @apply border-2 border-chm-red bg-white focus:ring-0 cursor-pointer;
    border-radius: 2px;
  }

  element-share-popup [type='checkbox']:checked,
  element-share-or-print-popup [type='checkbox']:checked {
    @apply bg-chm-red;
  }

  [type='radio'] {
    @apply border-2 border-chm-red bg-white focus:ring-0 cursor-pointer;
  }

  [type='radio']:checked {
    @apply border-chm-red bg-white;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(212 2 28)' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
  }

  [type='radio']:disabled {
    @apply border-chm-gray bg-white;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(166 166 166)' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
  }

  [type='radio']:disabled:not(:checked) {
    @apply border-chm-gray bg-white;
    background-image: none;
  }

  textarea,
  input[type='tel'],
  input[type='email'],
  input[type='text'],
  input[type='password'],
  input[type='date'] {
    @apply body border-primary-gray;
    box-shadow: none !important;
    resize: none;
  }

  element-poi-edit element-input textarea,
  element-poi-edit element-input input[type='tel'],
  element-poi-edit element-input input[type='email'],
  element-poi-edit element-input input[type='text'],
  element-poi-edit element-input input[type='date'] {
    box-shadow: none !important;
    resize: none;
  }

  textarea:hover,
  textarea:focus,
  input[type='email']:focus,
  input[type='email']:hover,
  input[type='tel']:focus,
  input[type='tel']:hover,
  input[type='text']:hover,
  input[type='date']:focus,
  input[type='text']:focus,
  input[type='password']:focus,
  input[type='password']:hover {
    @apply border-chm-focus-red;
  }

  textarea.error,
  input[type='email'].error,
  input[type='tel'].error,
  input[type='text'].error {
    @apply border-danger;
  }

  textarea:disabled,
  input[type='checkbox']:disabled,
  input[type='email']:disabled,
  input[type='tel']:disabled,
  input[type='text']:disabled,
  input[type='date']:disabled {
    @apply body border-primary-gray bg-page-main text-disabled;
  }

  .resp-px {
    @apply px-4 sm:px-12 mdxx:px-16;
  }

  .h-top-message {
    @apply h-[calc(theme('spacing.header-zero')*2)] sm:h-header-sm mdxx:h-header-mdxx;
  }

  /* full viewport height for b2b*/
  .h-screen-wo-header-b2b {
    @apply h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero'))]
    md:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm'))]
    xxl:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-mdxx'))];
  }

  /* full viewport height minus the header */
  .h-screen-wo-header {
    @apply h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero'))]
    sm:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm'))]
    mdxx:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-mdxx'))];
  }

  .h-screen-wo-header-message {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3))]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2))]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2))];
  }

  .min-h-screen-wo-header {
    @apply min-h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero'))]
    sm:min-h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm'))]
    mdxx:min-h-[calc((var(--vh,1vh)*100)-theme('spacing.header-mdxx'))];
  }

  .min-h-screen-wo-header-message {
    @apply min-h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3))]
    sm:min-h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2))]
    mdxx:min-h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2))];
  }

  /* full viewport height minus the header and route / segment title */
  .h-screen-wo-header-title {
    --header-plus-title-zero: calc(
      theme('spacing.header-zero') + theme('spacing.segment-title-height-zero')
    );
    --header-plus-title-sm: calc(
      theme('spacing.header-sm') + theme('spacing.segment-title-height-sm')
    );
    --header-plus-title-mdxx: calc(
      theme('spacing.header-mdxx') + theme('spacing.segment-title-height-mdxx')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-sm))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-mdxx))];
  }

  /* full viewport height minus the header, message, route / segment title and profile with facts (one line for sx) */
  .h-screen-wo-header-title-profile-message {
    --header-plus-title-message-and-profile-zero: calc(
      (theme('spacing.header-zero') * 3) +
        theme('spacing.segment-title-with-profile-height-zero')
    );
    --header-plus-title-message-and-profile-sm: calc(
      (theme('spacing.header-sm') * 2) +
        theme('spacing.segment-title-with-profile-height-zero')
    );
    --header-plus-title-message-and-profile-md: calc(
      (theme('spacing.header-sm') * 2) +
        theme('spacing.segment-title-with-profile-height-md')
    );
    --header-plus-title-message-and-profile-mdxx: calc(
      (theme('spacing.header-mdxx') * 2) +
        theme('spacing.segment-title-with-profile-height-md')
    );
    --header-plus-title-message-and-profile-lg: calc(
      (theme('spacing.header-mdxx') * 2) +
        theme('spacing.segment-title-with-profile-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-message-and-profile-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-message-and-profile-sm))]
    md:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-message-and-profile-md))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-message-and-profile-mdxx))]
    lg:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-message-and-profile-lg))];
  }

  /* full viewport height minus the header and route / segment title and profile with facts (one line for sx) */
  .h-screen-wo-header-title-profile {
    --header-plus-title-and-profile-zero: calc(
      theme('spacing.header-zero') +
        theme('spacing.segment-title-with-profile-height-zero')
    );
    --header-plus-title-and-profile-md: calc(
      theme('spacing.header-sm') +
        theme('spacing.segment-title-with-profile-height-md')
    );
    --header-plus-title-and-profile-sm: calc(
      theme('spacing.header-sm') +
        theme('spacing.segment-title-with-profile-height-zero')
    );
    --header-plus-title-and-profile-mdxx: calc(
      theme('spacing.header-mdxx') +
        theme('spacing.segment-title-with-profile-height-md')
    );
    --header-plus-title-and-profile-lg: calc(
      theme('spacing.header-mdxx') +
        theme('spacing.segment-title-with-profile-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-and-profile-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-and-profile-sm))]
    md:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-and-profile-md))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-and-profile-mdxx))]
    lg:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-and-profile-lg))];
  }

  .h-screen-wo-header-title-message {
    --header-plus-title-zero: calc(
      (theme('spacing.header-zero') * 3) +
        theme('spacing.segment-title-height-zero')
    );
    --header-plus-title-sm: calc(
      (theme('spacing.header-sm') * 2) +
        theme('spacing.segment-title-height-sm')
    );
    --header-plus-title-mdxx: calc(
      (theme('spacing.header-mdxx') * 2) +
        theme('spacing.segment-title-height-mdxx')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-sm))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-title-mdxx))];
  }

  /* full viewport height minus the header and tour title */
  .h-screen-tour-header-title {
    --tour-header-plus-title-zero: calc(
      theme('spacing.header-zero') + theme('spacing.tour-title-height-zero')
    );
    --tour-header-plus-title-sm: calc(
      theme('spacing.header-sm') + theme('spacing.tour-title-height-sm')
    );
    --tour-header-plus-title-md: calc(
      theme('spacing.header-sm') + theme('spacing.tour-title-height-md')
    );
    --tour-header-plus-title-mdxx: calc(
      theme('spacing.header-mdxx') + theme('spacing.tour-title-height-md')
    );
    --tour-header-plus-title-lg: calc(
      theme('spacing.header-mdxx') + theme('spacing.tour-title-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-zero)-var(--elevation-profile-height-small))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-sm)-var(--elevation-profile-height-small))]
    md:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-md)-var(--elevation-profile-height-medium))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-mdxx)-var(--elevation-profile-height-medium))]
    lg:lg:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-lg)-var(--elevation-profile-height-large))];
  }

  .h-screen-tour-header-title-message {
    --tour-header-plus-title-zero: calc(
      (theme('spacing.header-zero') * 3) +
        theme('spacing.tour-title-height-zero')
    );
    --tour-header-plus-title-sm: calc(
      (theme('spacing.header-sm') * 2) + theme('spacing.tour-title-height-sm')
    );
    --tour-header-plus-title-md: calc(
      (theme('spacing.header-sm') * 2) + theme('spacing.tour-title-height-md')
    );
    --tour-header-plus-title-mdxx: calc(
      (theme('spacing.header-mdxx') * 2) + theme('spacing.tour-title-height-md')
    );
    --tour-header-plus-title-lg: calc(
      (theme('spacing.header-mdxx') * 2) + theme('spacing.tour-title-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-zero)-var(--elevation-profile-height-small))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-sm)-var(--elevation-profile-height-small))]
    md:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-md)-var(--elevation-profile-height-medium))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-mdxx)-var(--elevation-profile-height-medium))]
    lg:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-lg)-var(--elevation-profile-height-large))];
  }

  .h-screen-tour-header-title-edit {
    --tour-header-plus-title-edit-zero: calc(
      (theme('spacing.header-zero')) +
        theme('spacing.tour-title-edit-height-zero')
    );
    --tour-header-plus-title-edit-sm: calc(
      (theme('spacing.header-sm')) + theme('spacing.tour-title-edit-height-sm')
    );
    --tour-header-plus-title-edit-md: calc(
      (theme('spacing.header-sm')) + theme('spacing.tour-title-edit-height-md')
    );
    --tour-header-plus-title-edit-mdxx: calc(
      (theme('spacing.header-mdxx')) +
        theme('spacing.tour-title-edit-height-md')
    );
    --tour-header-plus-title-edit-lg: calc(
      (theme('spacing.header-mdxx')) +
        theme('spacing.tour-title-edit-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-zero)-var(--elevation-profile-height-small))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-sm)-var(--elevation-profile-height-small))]
    md:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-md)-var(--elevation-profile-height-medium))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-mdxx)-var(--elevation-profile-height-medium))]
    lg:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-lg)-var(--elevation-profile-height-large))];
  }

  .h-screen-tour-header-title-edit-message {
    --tour-header-plus-title-edit-zero: calc(
      (theme('spacing.header-zero') * 3) +
        theme('spacing.tour-title-edit-height-zero')
    );
    --tour-header-plus-title-edit-sm: calc(
      (theme('spacing.header-sm') * 2) +
        theme('spacing.tour-title-edit-height-sm')
    );
    --tour-header-plus-title-edit-md: calc(
      (theme('spacing.header-sm') * 2) +
        theme('spacing.tour-title-edit-height-md')
    );
    --tour-header-plus-title-edit-mdxx: calc(
      (theme('spacing.header-mdxx') * 2) +
        theme('spacing.tour-title-edit-height-md')
    );
    --tour-header-plus-title-edit-lg: calc(
      (theme('spacing.header-mdxx') * 2) +
        theme('spacing.tour-title-edit-height-lg')
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-zero)-var(--elevation-profile-height-small))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-sm)-var(--elevation-profile-height-small))]
    md:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-md)-var(--elevation-profile-height-medium))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-mdxx)-var(--elevation-profile-height-medium))]
    lg:h-[calc((var(--vh,1vh)*100)-var(--tour-header-plus-title-edit-lg)-var(--elevation-profile-height-large))];
  }

  .h-screen-wo-header-gallery {
    --header-plus-gallery-zero: calc(theme('spacing.header-zero') + 236px);
    --header-plus-gallery-sm: calc(theme('spacing.header-sm') + 236px);
    --header-plus-gallery-mdxx: calc(theme('spacing.header-mdxx') + 236px);
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-sm))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-mdxx))];
  }

  .h-screen-wo-header-gallery-message {
    --header-plus-gallery-zero: calc(
      (theme('spacing.header-zero') * 3) + 236px
    );
    --header-plus-gallery-sm: calc((theme('spacing.header-sm') * 2) + 236px);
    --header-plus-gallery-mdxx: calc(
      (theme('spacing.header-mdxx') * 2) + 236px
    );
    @apply h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-zero))]
    sm:h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-sm))]
    mdxx:h-[calc((var(--vh,1vh)*100)-var(--header-plus-gallery-mdxx))];
  }

  .h-land-selector-gap {
    @apply h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero')-theme('spacing.land-rect')*2-2*theme('spacing.land-gap-xl'))]
    sm:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm')-theme('spacing.land-rect')-theme('spacing.land-pb')/2)]
    mdx:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm')-theme('spacing.land-rect')-theme('spacing.land-pb'))]
    mdxx:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-mdxx')-theme('spacing.land-rect-mdxx')-theme('spacing.land-pb'))];
  }

  .h-land-selector-gap-message {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-theme('spacing.land-rect')*2-2*theme('spacing.land-gap-xl'))]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.land-rect')-theme('spacing.land-pb')/2)]
    mdx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.land-rect')-theme('spacing.land-pb'))]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2)-theme('spacing.land-rect-mdxx')-theme('spacing.land-pb'))];
  }

  .h-cat-selector-gap {
    @apply sm:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-sm')-theme('spacing.region')-theme('spacing.land-gap-xl')*2-theme('spacing.logo-m'))]
    mdxx:h-[calc((var(--vh,1vh)*100)-theme('spacing.header-mdxx')-theme('spacing.region-mdxx')-theme('spacing.land-gap-xl')*2-theme('spacing.logo-m'))] !important;
  }

  .h-cat-selector-gap-message {
    @apply sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.region')-theme('spacing.land-gap-xl')*2-theme('spacing.logo-m'))]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2)-theme('spacing.region-mdxx')-theme('spacing.land-gap-xl')*2-theme('spacing.logo-m'))] !important;
  }

  .h-cat-selector-gap-1 {
    @apply h-cat-selector-gap
    h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero')-theme('spacing.region')-theme('spacing.land-gap-s')*2-theme('spacing.logo-m'))];
  }

  .h-cat-selector-gap-1-message {
    @apply h-cat-selector-gap-message
    h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-theme('spacing.region')-theme('spacing.land-gap-s')*2-theme('spacing.logo-m'))];
  }

  .h-cat-selector-gap-2 {
    @apply h-cat-selector-gap
    h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero')-2*theme('spacing.region')-theme('spacing.land-gap-s')*3-theme('spacing.logo-m'))];
  }

  .h-cat-selector-gap-2-message {
    @apply h-cat-selector-gap-message
    h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-2*theme('spacing.region')-theme('spacing.land-gap-s')*3-theme('spacing.logo-m'))];
  }

  .h-cat-selector-gap-3 {
    @apply h-cat-selector-gap
    h-[calc((var(--vh,1vh)*100)-theme('spacing.header-zero')-3*theme('spacing.region')-theme('spacing.land-gap-s')*4-theme('spacing.logo-m'))];
  }

  .h-cat-selector-gap-3-message {
    @apply h-cat-selector-gap-message
    h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-3*theme('spacing.region')-theme('spacing.land-gap-s')*4-theme('spacing.logo-m'))];
  }

  .p-content {
    @apply px-m-content-zero
    xxs:px-m-content-xxs
    sm:px-m-content-sm
    md:px-m-content-md
    mdxx:px-m-content-mdxx
    lg:px-m-content-lg
    xl:px-m-content-xl;
  }

  .p-inner-content {
    @apply px-[12px] sm:px-[16px] mdxx:px-[24px];
  }

  .w-content {
    @apply max-w-content
    w-[calc(100vw-theme('spacing.m-content-zero')*2)]
    xxs:w-[calc(100vw-theme('spacing.m-content-xxs')*2)]
    sm:w-[calc(100vw-theme('spacing.m-content-sm')*2)]
    md:w-[calc(100vw-theme('spacing.m-content-md')*2)]
    mdxx:w-[calc(100vw-theme('spacing.m-content-mdxx')*2)]
    lg:w-[calc(100vw-theme('spacing.m-content-lg')*2)]
    xl:w-[calc(100vw-theme('spacing.m-content-xl')*2)];
  }

  .w-list-popup-content {
    @apply max-w-content
    w-[calc(100vw-theme('spacing.m-content-zero')*2)]
    xxs:w-[calc(100vw-theme('spacing.m-content-xxs')*2)]
    sm:w-[calc(100vw-theme('spacing.m-content-sm')*2)]
    md:w-[calc(100vw-theme('spacing.m-content-md')*2-40px)]
    mdxx:w-[calc(100vw-theme('spacing.m-content-mdxx')*2-40px)]
    lg:w-[calc(100vw-theme('spacing.m-content-lg')*2)]
    xxl:w-[calc((8/12)*(100vw-theme('spacing.m-content-xl')*2))];
  }

  .top-msg-w-content {
    @apply xxl:max-w-content
    w-[calc(100vw-theme('spacing.m-content-zero')*2)]
    xxs:w-[calc(100vw-theme('spacing.m-content-xxs')*2)]
    sm:w-[calc(100vw-theme('spacing.m-content-sm')*2)]
    md:w-[calc(100vw-theme('spacing.m-content-md')*2)]
    mdxx:w-[calc(100vw-theme('spacing.m-content-mdxx')*2)]
    lg:w-[calc(100vw-theme('spacing.m-content-lg')*2)]
    xl:w-[calc(100vw-theme('spacing.m-content-xl')*2)]
    xl:min-w-0;
  }

  .p-content-left {
    @apply pl-m-content-zero
    xxs:pl-m-content-xxs
    sm:pl-m-content-sm
    md:pl-m-content-md
    mdxx:pl-m-content-mdxx
    lg:pl-m-content-lg
    xl:pl-m-content-xl
    xxl:pl-[calc(theme('spacing.m-content-xl')+(100vw-theme('spacing.m-content-xl')*2)*(2/12))]
    max:pl-[calc(50vw-theme('maxWidth.content')/2+theme('maxWidth.content')*(2/12))] !important;
  }

  .p-content-left-overlay {
    @apply mdxx:pl-[calc((100vw-theme('spacing.m-content-mdxx')*2)*(1/12)-10px)]
    lg:pl-[calc((100vw-theme('spacing.m-content-lg')*2)*(1/12)-10px)]
    xl:pl-[calc((100vw-theme('spacing.m-content-xl')*2)*(2/12)-10px)]
    max:pl-[calc(theme('maxWidth.content')*(2/12)-10px)] !important;
  }

  .p-gallery-left {
    @apply pl-[calc(theme('spacing.m-content-zero')-(50vw-50%))]
    xxs:pl-[calc(theme('spacing.m-content-xxs')-(50vw-50%))]
    sm:pl-[calc(theme('spacing.m-content-sm')-(50vw-50%))]
    md:pl-[calc(theme('spacing.m-content-md')-(100vw-100%)/2)]
    mdxx:pl-[calc(theme('spacing.m-content-mdxx')-(100vw-100%)/2)]
    lg:pl-[calc(theme('spacing.m-content-lg')-(100vw-100%)/2)]
    xl:pl-[calc(theme('spacing.m-content-xl')-(100vw-100%)/2)]
    xxl:pl-[calc(theme('spacing.m-content-xl')+(100vw-theme('spacing.m-content-xl')*2)*(2/12)-(50vw-50%))]
    max:pl-[calc(50vw-theme('maxWidth.content')/2+theme('maxWidth.content')*(2/12)-(50vw-50%))] !important;
  }

  .p-plus-menu-left {
    @apply xxl:pl-[calc((100vw-theme('spacing.m-content-xl')*2)*(2/12))]
    max:pl-[calc(50vw-theme('maxWidth.content')/2+theme('maxWidth.content')*(2/12)-80px)] !important;
  }

  .mr-carousel-nav {
    @apply mr-[20px] xxs:mr-[32px] sm:mr-[40px] md:mr-[80px] mdxx:mr-[119px] lg:mr-[117px] xl:mr-[132px];
  }

  .card-title {
    @apply text-card-title md:text-md-card-title lg:text-xxl-card-title;
  }

  .card-title-map {
    @apply text-card-title md:text-md-card-title;
  }

  .card-subtitle {
    @apply text-card-subtitle md:text-md-card-subtitle xxl:text-xxl-card-subtitle;
  }

  .wide-news-card-size {
    @apply sm:h-[416px] sm:w-full xl:h-[441px] xl:w-full;
  }

  .round-news-card-size {
    @apply h-[170px] sm:h-[300px] xl:h-[420px];
  }

  .map-btn,
  .beta-feedback-btn {
    @apply fixed z-[45]
    bottom-[calc(2*(theme('spacing.land-rect')+theme('spacing.land-gap-xl'))+theme('spacing.crsl-nav-gap')+56px)]
    right-[20px] h-nav-button-big-s w-nav-button-big-s xxs:right-[32px] sm:bottom-[calc(theme('spacing.land-rect')+theme('spacing.land-pb')+theme('spacing.crsl-nav-gap')+48px)] sm:right-[40px]
    md:bottom-[48px] md:right-[20px] md:h-nav-button-big-m md:w-nav-button-big-m mdxx:bottom-[40px]
    mdxx:right-[32px]
    mdxx:h-nav-button-big-l
    mdxx:w-nav-button-big-l
    mouse:hover:bg-chm-focus-red;
  }

  .plus-menu-width {
    @apply max-w-[calc(100vw-(theme('spacing.nav-button-big-s')*3))];
  }

  .carousel-nav-btns {
    @apply bottom-[calc(2*(theme('spacing.land-rect')+theme('spacing.land-gap-xl'))+theme('spacing.crsl-nav-gap'))]
    sm:bottom-[calc(theme('spacing.land-rect')+theme('spacing.land-pb')+theme('spacing.crsl-nav-gap'))]
    mdxx:bottom-[calc(theme('spacing.land-rect-mdxx')+theme('spacing.land-pb')+theme('spacing.crsl-nav-gap'))];
  }

  .land-rect {
    @apply z-40 flex h-land-rect flex-1 flex-col
    justify-between sm:min-w-land-rect-zero md:min-w-land-rect mdxx:h-land-rect-mdxx mdxx:min-w-land-rect-mdxx;
  }

  .button-tag {
    @apply border-[4px] border-transparent;
  }

  .button-tag-focus {
    @apply rounded border-[4px] border-chm-focus-red;
  }

  element-checkbox-options div > span.icon-placeholder::after,
  element-checkbox:not(.tag) input ~ span::after,
  element-line-checkbox input ~ span::after {
    @apply bg-[url('./src/img/checkbox-unchecked.svg')];
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }

  element-checkbox-options div > span.icon-placeholder::after,
  element-checkbox input:not(.tag) ~ span::after {
    position: absolute;
    top: -12px;
    right: -12px;
  }

  element-line-checkbox input ~ span::after {
    display: flex;
    align-items: center;
  }

  element-line-checkbox input:checked ~ span::after,
  element-checkbox:not(.tag) input:checked ~ span::after {
    @apply bg-[url('./src/img/checkbox-checked.svg')];
  }

  element-checkbox-options div.checked > span.icon-placeholder::after {
    @apply bg-[url('./src/img/checkbox-checked.svg')];
  }

  element-checkbox-options div.part-checked > span.icon-placeholder::after {
    @apply bg-[url('./src/img/checkbox-part-checked.svg')];
  }

  element-line-checkbox input.sign ~ span::after {
    @apply bg-chm-red;
    mask: url('./src/img/unchecked_checkbox_sign.svg') no-repeat center;
    -webkit-mask: url('./src/img/unchecked_checkbox_sign.svg') no-repeat center;
    background-image: none;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }

  element-line-checkbox input.sign:checked ~ span::after {
    mask: url('./src/img/checked_checkbox_sign.svg') no-repeat center;
    -webkit-mask: url('./src/img/checked_checkbox_sign.svg') no-repeat center;
  }

  element-line-checkbox:focus input.sign:checked ~ span::after,
  element-line-checkbox:focus input.sign:checked ~ span::after,
  element-line-checkbox:focus input.sign ~ span::after,
  element-line-checkbox:hover input.sign ~ span::after {
    @apply bg-chm-focus-red;
  }

  .routes-view-btn {
    @apply h-[24px] w-[24px] cursor-pointer bg-black hover:bg-chm-focus-red;
  }

  .content-title-filter-margin {
    @apply mt-[20px] md:mb-[8px] md:mt-[24px] lg:mb-[16px] lg:mt-[32px] xl:mb-[12px];
  }

  .content-title-filter {
    @apply content-title-filter-margin leading-[19px] sm:leading-[28px] md:leading-[46px] lg:leading-[42px] xl:leading-[60px];
  }

  .content-title-margin {
    @apply mb-[16px] mt-[20px] md:mb-[20px] md:mt-[24px] lg:mb-[48px] lg:mt-[32px];
  }

  .content-title {
    @apply content-title-margin leading-[19px] sm:leading-[28px] md:leading-[46px] lg:leading-[42px] xl:leading-[60px];
  }

  .content-title-font {
    @apply h1 font-bold;
  }

  .land-img {
    @apply h-logo-m w-logo-m bg-cover bg-no-repeat;
  }

  .route-logo {
    @apply land-img mdxx:h-logo-l mdxx:w-logo-l;
  }

  .dropdown-list {
    @apply inline-flex w-full sm:max-w-[240px] cursor-pointer items-center justify-between
    border border-primary-gray bg-white text-left disabled:cursor-default md:max-w-[320px];
  }

  .dropdown-list ul {
    @apply max-h-[200px] overflow-y-auto;
  }

  .dropdown-list li {
    @apply px-[20px] hover:bg-[#F1F3F5] hover:text-chm-focus-red py-[5px] sm:py-[10px];
  }

  .dropdown-btn {
    @apply dropdown-list h-[40px] px-[16px] disabled:border-chm-gray disabled:bg-page-main disabled:text-chm-gray
    md:h-[56px] sm:px-[20px] mouse:hover:border-chm-focus-red;
  }

  .bg-layer:hover > div:first-child,
  .land-filter:hover .land-img {
    @apply border-chm-focus-red;
  }

  .popup-content-poi {
    @apply fixed top-[calc(theme('spacing.header-zero')+theme('spacing.land-pb'))] max-h-[calc(100%-theme('spacing.header-zero')-theme('spacing.land-pb'))] overflow-x-auto
    bg-white
    shadow-popup
    sm:top-[calc(theme('spacing.header-sm')+theme('spacing.land-pb'))]
    sm:max-h-[calc(100%-theme('spacing.header-sm')-theme('spacing.land-pb'))]
    mdxx:top-[calc(theme('spacing.header-mdxx')+theme('spacing.land-pb'))]
    mdxx:max-h-[calc(100%-theme('spacing.header-mdxx')-theme('spacing.land-pb'))];
  }

  .popup-content {
    @apply w-content popup-content-poi;
  }

  .popup-content-import-tour {
    @apply w-list-popup-content popup-content-poi;
  }

  .popup-content-old-map-or-login {
    overflow-x: hidden !important;
    @apply mx-[14px] my-[4px] sm:grid-cols-8  bg-white shadow-popup popup-content-poi;
  }

  .popup-content-old-map-or-login-xl {
    @apply xl:w-8/12 xl:mx-auto;
    position: static !important;
  }

  .popup-content-old-map-or-login-overlay {
    @apply sm:w-content items-center justify-center sm:mx-auto;
  }

  .popup-content.message,
  .popup-content-poi.message {
    @apply fixed top-[calc((theme('spacing.header-zero')*3)+theme('spacing.land-pb'))] max-h-[calc(100%-(theme('spacing.header-zero')*3)-theme('spacing.land-pb'))] overflow-x-auto
    bg-white
    shadow-popup
    sm:top-[calc((theme('spacing.header-sm')*2)+theme('spacing.land-pb'))]
    sm:max-h-[calc(100%-(theme('spacing.header-sm')*2)-theme('spacing.land-pb'))]
    mdxx:top-[calc((theme('spacing.header-mdxx')*2)+theme('spacing.land-pb'))]
    mdxx:max-h-[calc(100%-(theme('spacing.header-mdxx')*2)-theme('spacing.land-pb'))];
  }

  /* height calculation: 100vh - header - space between header and overlay - overlay header */
  .popup-content.preview-map {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero'))-theme('spacing.land-pb')-8px)] px-[8px]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm'))-theme('spacing.land-pb')-16px)] sm:px-[12px]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx'))-theme('spacing.land-pb')-16px)];
  }

  .popup-content.preview-map.portrait {
    @apply px-[8px] sm:px-[12px];
    width: auto;
    max-width: 95vw;
  }

  /* height calculation: 100vh - (header + message) - space between header and overlay - overlay header */
  .popup-content.message.preview-map {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-theme('spacing.land-pb')-8px)]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.land-pb')-16px)]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2)-theme('spacing.land-pb')-16px)];
  }

  .popup-content.preview-map.portrait .map-container {
    @apply aspect-[510/710] w-full;
  }

  /* height calculation: 100vh - (header + message) - space between header and overlay - overlay header - in/out paddings */
  .popup-content.message.preview-map .map-container {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero')*3)-theme('spacing.land-pb')-8px-8px-52px)]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.land-pb')-16px-12px-56px)]
    md:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm')*2)-theme('spacing.land-pb')-16px-12px-64px)]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2)-theme('spacing.land-pb')-16px-12px-72px)]
    xl:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx')*2)-theme('spacing.land-pb')-16px-12px-76px)];
  }

  /* height calculation: 100vh - header - space between header and overlay - overlay header - in/out paddings */
  .popup-content.preview-map .map-container {
    @apply h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-zero'))-theme('spacing.land-pb')-8px-8px-52px)]
    sm:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm'))-theme('spacing.land-pb')-16px-12px-56px)]
    md:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-sm'))-theme('spacing.land-pb')-16px-12px-64px)]
    mdxx:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx'))-theme('spacing.land-pb')-16px-12px-72px)]
    xl:h-[calc((var(--vh,1vh)*100)-(theme('spacing.header-mdxx'))-theme('spacing.land-pb')-16px-12px-76px)];
  }

  .popup-content.notification-dialog {
    @apply w-content -ml-[12px]
    md:w-[calc((100vw-theme('spacing.m-content-md')*2)/2)]
    mdxx:w-[calc((100vw-theme('spacing.m-content-mdxx')*2)/2)]
    lg:w-[calc((100vw-theme('spacing.m-content-lg')*2)/2)]
    xl:w-[calc((100vw-theme('spacing.m-content-xl')*2)/2)];
  }

  element-filter-popup .popup-filter-or-layertree-content,
  element-filter-popup .popup-filter-or-layertree-content.message {
    @apply bg-chm-light-gray;
  }

  element-layers .popup-filter-or-layertree-content,
  element-layers .popup-filter-or-layertree-content.message {
    @apply bg-layers-popup;
  }

  element-static-page hr {
    @apply my-[20px] md:my-[24px] lg:my-[36px] border-t border-[#707070] border-solid;
  }

  /* left calculation: 50% - (popup width / 2) */
  .filter-popup-position {
    @apply left-[calc(50%-(294px/2))] sm:left-[calc(50%-(424px/2))];
  }

  .popup {
    @apply fixed
    left-0
    right-0 z-[60] overflow-y-auto overflow-x-hidden top-[calc(theme('spacing.header-zero')+theme('spacing.land-pb'))] sm:top-[calc(theme('spacing.header-sm')+theme('spacing.land-pb'))];
  }

  .popup.message {
    @apply fixed
    left-0
    right-0 overflow-y-auto overflow-x-hidden top-[calc((theme('spacing.header-zero')*3)+theme('spacing.land-pb'))] sm:top-[calc((theme('spacing.header-sm')*2)+theme('spacing.land-pb'))];
  }

  .max-h-big-gallery {
    @apply max-h-[calc((var(--vh,1vh)*100)-101px)] sm:max-h-[calc((var(--vh,1vh)*100)-64px)] mdxx:max-h-[calc((var(--vh,1vh)*100)-88px)];
  }

  .h1 {
    @apply text-h1 sm:text-sm-h1 md:text-md-h1 lg:text-lg-h1 xl:text-xl-h1 xxl:text-xxl-h1;
  }

  .h2 {
    @apply text-h2 sm:text-sm-h2 md:text-md-h2 lg:text-lg-h2;
  }

  .h3 {
    @apply text-h3 sm:text-sm-h3 md:text-md-h3 lg:text-lg-h3 xl:text-xl-h3;
  }

  .h3-b2b {
    @apply text-sm-h3-b2b md:text-md-h3-b2b lg:text-lg-h3-b2b;
  }

  .h4 {
    @apply text-h4 sm:text-sm-h4 md:text-md-h4 lg:text-lg-h4 xl:text-xl-h4;
  }

  .h5 {
    @apply text-h5 sm:text-sm-h5 md:text-md-h5 lg:text-lg-h5 xl:text-xl-h5;
  }

  .h6 {
    @apply text-h6 sm:text-sm-h6 md:text-md-h6 lg:text-lg-h6 xl:text-xl-h6;
  }

  .lead {
    @apply text-lead sm:text-sm-lead md:text-md-lead lg:text-lg-lead;
  }

  .route-title-with-profile {
    @apply text-route-title-with-profile sm:text-sm-route-title-with-profile md:text-md-route-title-with-profile lg:text-lg-route-title-with-profile;
  }

  .body {
    @apply text-body sm:text-sm-body md:text-md-body lg:text-lg-body xl:text-xl-body;
  }

  .small-text {
    @apply text-sm-body lg:text-md-body;
  }

  .distinction {
    @apply text-distinction md:text-md-distinction;
  }

  .accordion {
    @apply text-left text-accordion md:text-md-accordion  lg:text-lg-accordion;
  }

  .award {
    @apply text-award sm:text-sm-award md:text-md-award;
  }

  .tiny-title {
    @apply text-tiny-title sm:text-sm-tiny-title md:text-md-tiny-title;
  }

  .md-tiny-title {
    @apply text-md-tiny-title;
  }

  .footer-link {
    @apply text-footer-link md:text-md-footer-link mdxx:text-mdxx-footer-link;
  }

  .footer-subtitle {
    @apply text-footer-subtitle-s sm:text-footer-subtitle-m;
  }

  .supporter-subtitle {
    @apply text-footer-subtitle-s text-tiny-title sm:text-footer-subtitle-m;
  }

  .layer-item:hover .highlight-part {
    @apply fill-chm-focus-red;
  }

  .poi-address-item {
    @apply flex h-[40px] items-center bg-page-main px-[16px] mdxx:h-[44px];
  }

  /* to use when a link with icon is not in a text context */
  .link-with-icon-alone {
    @apply truncate text-url sm:text-sm-url md:text-md-url lg:text-lg-url;
  }

  /* to use when for a link with icon font-size and line-height should be taken from the surrounding context */
  .link-with-icon-in-context {
    @apply !font-bold;
  }

  .link-with-icon-in-context-truncate {
    @apply link-with-icon-in-context truncate;
  }

  .quote-teaser-text {
    @apply text-quote-teaser sm:text-sm-quote-teaser md:text-md-quote-teaser xl:text-xl-quote-teaser;
  }

  .overflow-overlay {
    overflow-y: auto; /* for FF */
    overflow-y: overlay; /* for Chrome */
  }

  .section-mt {
    @apply mt-[40px] sm:mt-[32px] md:mt-[40px] lg:mt-[60px] xl:mt-[60px];
  }

  .w-notification {
    @apply max-w-full
    w-[--notification-width-zero]
    xxs:w-[--notification-width-xxs]
    sm:w-[--notification-width-sm]
    md:w-[--notification-width-md]
    mdxx:w-[--notification-width-mdxx]
    lg:w-[--notification-width-lg]
    xl:w-[--notification-width-xl];
  }

  .notification-position {
    @apply ml-[calc(50%-var(--notification-width-zero)/2)]
    xxs:ml-[calc(50%-var(--notification-width-xxs)/2)]
    sm:ml-[calc(50%-var(--notification-width-sm)/2)]
    md:ml-[calc(50%-var(--notification-width-md)/2)]
    mdxx:ml-[calc(50%-var(--notification-width-mdxx)/2)]
    lg:ml-[calc(50%-var(--notification-width-lg)/2)]
    xl:ml-[calc(50%-var(--notification-width-xl)/2)];
  }

  .notification-information {
    @apply bg-snackbar-information text-white;
  }

  .snackbar-link {
    @apply bg-snackbar-information text-white;
  }

  .notification-success {
    @apply bg-snackbar-success text-black;
  }

  .notification-error {
    @apply bg-snackbar-error text-black;
  }

  .bg-loader-animated {
    @apply bg-[url('./src/img/sm_loader_icon_animated.svg')];
  }

  .bg-loader-animated-white {
    @apply bg-[url('./src/img/sm_loader_icon_animated_white.svg')];
  }

  .bg-delete {
    @apply bg-white bg-[url('./src/img/i_plus_delete.svg')] bg-center bg-no-repeat;
  }

  .bg-upload {
    @apply bg-white bg-[url('./src/img/i_upload_file.svg')] bg-center bg-no-repeat;
  }

  .bg-category {
    @apply bg-[url('./src/img/i_categorie.svg')];
  }

  .info-btn {
    @apply bg-chm-gray;
  }

  .info-btn:hover,
  .info-btn.active {
    @apply bg-white;
  }

  .info-btn svg {
    @apply fill-white;
  }

  .info-btn:hover svg,
  .info-btn.active svg {
    @apply fill-chm-gray;
  }

  element-teasers element-teaser-text:first-child {
    @apply -mb-[32px] md:-mb-[40px] xl:-mb-[50px];
  }

  #map {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  element-input-range input[type='range']::-webkit-slider-thumb,
  element-input-range input[type='range']::-moz-range-thumb {
    @apply h-thumb w-thumb bg-chm-red;
  }
  element-input-range input[type='range']::-moz-range-progress {
    background-color: transparent;
  }

  element-tour-config
    element-input-range
    input[type='range'].section-input-range::-webkit-slider-thumb,
  element-tour-config
    element-input-range
    input[type='range'].section-input-range::-moz-range-thumb {
    @apply bg-[auto_36px] bg-chm-focus-red -mt-[6px];
  }

  element-input-range.sledging input[type='range']::-webkit-slider-thumb,
  element-input-range.sledging input[type='range']::-moz-range-thumb {
    @apply bg-sledge-icon time-type-icon;
  }

  element-input-range.cross_country_skiing
    input[type='range']::-webkit-slider-thumb,
  element-input-range.cross_country_skiing
    input[type='range']::-moz-range-thumb {
    @apply bg-cross-country-icon time-type-icon;
  }

  element-input-range.skating input[type='range']::-webkit-slider-thumb,
  element-input-range.skating input[type='range']::-moz-range-thumb {
    @apply bg-skate-icon time-type-icon;
  }

  element-input-range.canoeing input[type='range']::-webkit-slider-thumb,
  element-input-range.canoeing input[type='range']::-moz-range-thumb {
    @apply bg-canoe-icon time-type-icon;
  }

  element-input-range.no input[type='range']::-webkit-slider-thumb,
  element-input-range.no input[type='range']::-moz-range-thumb {
    @apply bg-no-time-type-icon time-type-icon;
  }

  element-input-range.mountain_biking input[type='range']::-webkit-slider-thumb,
  element-input-range.mountain_biking input[type='range']::-moz-range-thumb {
    @apply bg-mountain-bike-icon time-type-icon;
  }

  element-input-range.snowshoe_trekking
    input[type='range']::-webkit-slider-thumb,
  element-input-range.snowshoe_trekking input[type='range']::-moz-range-thumb {
    @apply bg-snowshoe-icon time-type-icon;
  }

  element-input-range.winter_hiking input[type='range']::-webkit-slider-thumb,
  element-input-range.winter_hiking input[type='range']::-moz-range-thumb {
    @apply bg-winter-hike-icon time-type-icon;
  }

  element-input-range.velo input[type='range']::-webkit-slider-thumb,
  element-input-range.velo input[type='range']::-moz-range-thumb {
    @apply bg-cycle-icon time-type-icon;
  }

  element-input-range.wander input[type='range']::-webkit-slider-thumb,
  element-input-range.wander input[type='range']::-moz-range-thumb {
    @apply bg-hike-icon time-type-icon;
  }

  .time-type-icon {
    @apply w-[40px] h-[40px] bg-[auto_40px] border-chm-focus-red border-2 rounded-full cursor-pointer bg-no-repeat;
  }

  .bg-hike-icon {
    @apply bg-[url('./src/img/timetype/hike.svg')] bg-center;
  }

  .bg-cycle-icon {
    @apply bg-[url('./src/img/timetype/cycle.svg')] bg-center;
  }

  .bg-mountain-bike-icon {
    @apply bg-[url('./src/img/timetype/mountainBike.svg')] bg-center;
  }

  .bg-canoe-icon {
    @apply bg-[url('./src/img/timetype/canoe.svg')] bg-center;
  }

  .bg-cross-country-icon {
    @apply bg-[url('./src/img/timetype/crossCountry.svg')] bg-center;
  }

  .bg-no-time-type-icon {
    @apply bg-[url('./src/img/timetype/noTimeType.svg')] bg-center;
  }

  .bg-skate-icon {
    @apply bg-[url('./src/img/timetype/skate.svg')] bg-center;
  }

  .bg-sledge-icon {
    @apply bg-[url('./src/img/timetype/sledge.svg')] bg-center;
  }

  .bg-snowshoe-icon {
    @apply bg-[url('./src/img/timetype/snowshoe.svg')] bg-center;
  }

  .bg-winter-hike-icon {
    @apply bg-[url('./src/img/timetype/winterHike.svg')] bg-center;
  }

  .bg-recorded-icon {
    @apply bg-[url('./src/img/timetype/recorded.svg')] bg-center;
  }
}

@tailwind components;

@layer components {
  .body-links-and-text {
    @apply text-body sm:text-sm-body md:text-md-body;
  }

  /* Save as above class, needed for respect of semantic html/classes with fields elements */
  .field-font {
    @apply text-body sm:text-sm-body md:text-md-body lg:text-lg-body;
  }

  .dropdown-field-font {
    @apply text-body sm:text-sm-body md:text-md-body lg:text-lg-body;
  }

  .button-structure {
    @apply flex items-center justify-center rounded-lg px-[16px] py-[12px] sm:px-[24px] sm:py-[17px];
  }

  .button-color-red {
    @apply button-structure body-links-and-text bg-chm-red font-bold text-white mouse:hover:bg-chm-focus-red;
  }

  .button-color-white {
    @apply button-structure body-links-and-text bg-white font-bold text-chm-red focus:bg-layers-popup focus:text-chm-focus-red mouse:hover:bg-page-main mouse:hover:text-chm-focus-red;
  }

  element-header .username-font {
    @apply distinction font-medium sm:font-semibold;
  }
  element-header .button-logged-nav {
    @apply body w-full px-[24px] py-[4px] sm:py-[8px] mouse:hover:bg-chm-tertiary-gray mouse:hover:text-chm-focus-red cursor-pointer;
  }

  element-teasers
    element-teaser-title-text-photo:not(:first-child)
    div.teaser-text,
  element-teasers element-teaser-text-photo:not(:first-child) div.teaser-text,
  element-teasers element-teaser-text:not(:first-child) div.teaser-text,
  element-teasers element-teaser-quote-photo:not(:first-child) div.teaser-text,
  element-teasers element-teaser-title-text-photo:first-child h1.teaser-text,
  element-teasers element-teaser-text-photo:first-child h1.teaser-text,
  element-teasers element-teaser-text:first-child h1.teaser-text,
  element-teasers element-teaser-quote-photo:first-child h1.teaser-text {
    display: -webkit-box;
  }

  element-teasers
    element-teaser-title-text-photo:not(:first-child)
    h1.teaser-text,
  element-teasers element-teaser-text-photo:not(:first-child) h1.teaser-text,
  element-teasers element-teaser-text:not(:first-child) h1.teaser-text,
  element-teasers element-teaser-quote-photo:not(:first-child) h1.teaser-text,
  element-teasers element-teaser-title-text-photo:first-child div.teaser-text,
  element-teasers element-teaser-text-photo:first-child div.teaser-text,
  element-teasers element-teaser-text:first-child div.teaser-text,
  element-teasers element-teaser-quote-photo:first-child div.teaser-text,
  page-b2b-home element-title-text-photo-tile h1.teaser-text,
  element-static-page element-title-text-photo-tile h1.teaser-text {
    @apply hidden;
  }

  .teaser-clamp-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@tailwind utilities;

@layer utilities {
  /* For cantons footer */
  .grid-cols-13 {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }
}

.checkbox-sign {
  top: -1em;
  right: -1em;
}

.i-view-card {
  mask: url('./src/img/i_view_card.svg') no-repeat center;
  -webkit-mask: url('./src/img/i_view_card.svg') no-repeat center;
}

.i-view-list {
  mask: url('./src/img/i_view_list.svg') no-repeat center;
  -webkit-mask: url('./src/img/i_view_list.svg') no-repeat center;
}

.i-view-list-layers {
  mask: url('./src/img/i_view_list.svg') no-repeat top;
  -webkit-mask: url('./src/img/i_view_list.svg') no-repeat top;
}

.disabled {
  opacity: 75%;
  cursor: not-allowed;
  pointer-events: none;
}

.pseudo-disabled {
  opacity: 75%;
  cursor: default;
}

.preloader {
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #00000070;
  z-index: 50;
  margin: -20px;
}

.flat-breaks br {
  content: '';
}

.flat-breaks br:after {
  content: ' ';
}

img.gallery {
  width: 275px;
  height: 220px;
}

.ranking svg {
  display: inline-block;
  vertical-align: top;
}

.info-content::after,
.info-content::before,
.coordinates-content::after,
.coordinates-content::before,
.shortlist-content::after,
.shortlist-content::before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.shortlist-content::after {
  border-top-color: white;
  border-top-width: 21px;
  border-right-width: 12px;
  border-bottom-width: 0;
  border-left-width: 12px;
  left: 30px;
  margin-left: -12px;
}

.coordinates-content::after {
  border-top-color: white;
  border-top-width: 11px;
  border-right-width: 6px;
  border-bottom-width: 0;
  border-left-width: 6px;
  left: 30px;
  margin-left: -6px;
}

.info-content::after {
  border-top-color: white;
  border-top-width: 11px;
  border-right-width: 6px;
  border-bottom-width: 0;
  border-left-width: 6px;
  right: 30px;
  margin-right: -6px;
}

.coordinates-content,
.info-content svg {
  @apply mouse:hover:fill-chm-focus-red;
}

.info-content {
  transform: translate(0, calc(-150% - 10px));
}

.swiper.focus-gallery .swiper-slide-active img {
  outline: 4px solid #ad091d;
  outline-offset: -4px;
}

.swiper-button-disabled {
  display: none !important;
}

/* Styling for the htmlPopup from swisstopo */
.htmlpopup-header {
  display: none;
}

.htmlpopup-container .cell-left {
  font-weight: 600;
}

.htmlpopup-container tbody tr {
  display: grid;
  margin-bottom: 16px;
}

/* hide the "link to object" cell */
.htmlpopup-container tbody tr:last-child {
  display: none;
}

.whitespace-nowrap br {
  content: '';
}

.whitespace-nowrap br:after {
  content: ' ';
}

.page-scroll-animation {
  transition: all 0.8s 0s ease-out;
}

.fade-in-animation {
  transition: all 0.8s 0.2s ease-in-out;
}

.fade-in-out-animation-o {
  transition: opacity 0.3s ease-in-out;
}

.fade-in-out-animation-h {
  transition: all 0.5s ease-in-out;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

.banner-title {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.banner-font {
  @apply text-banner-text sm:text-sm-banner-text xxl:text-xxl-banner-text;
}

.banner-text {
  padding-right: 0px;
}

.element-checkbox {
  display: table-cell;
  height: fit-content;
}
.element-checkbox-options {
  max-width: inherit;
}

.date-input {
  padding-top: 14px;
}

elevation-profile text {
  @apply text-height-profile md:text-md-height-profile lg:text-lg-height-profile text-chm-gray;
}

/* To prevent cropped labels in the x axis */
elevation-profile svg {
  overflow: visible;
}

elevation-profile .axis .domain,
elevation-profile .grid .domain,
elevation-profile .grid.x {
  display: none;
}

elevation-profile line {
  shape-rendering: crispEdges;
}

elevation-profile .axis line,
elevation-profile .grid line {
  @apply stroke-chm-gray;
}

elevation-profile .axis.y .tick line {
  display: none;
}

elevation-profile .axis.y .tick text {
  transform: translateX(6px);
}

elevation-profile .axis.x .tick line {
  transform: translateY(-6px);
}

elevation-profile .pointer-line {
  @apply stroke-chm-gray;
  stroke-opacity: 0.3;
  stroke-width: 2;
  stroke-dasharray: 3, 3;
}

elevation-profile .pointer-circle {
  fill: var(--tour-color);
  stroke: #fff;
  stroke-width: 3;
}

elevation-profile .pointer-circle-outline {
  fill: #000;
  fill-opacity: 0.2;
}

elevation-profile .elevation,
elevation-profile .elevation.highlight {
  stroke: var(--tour-color);
  stroke-width: 2;
  stroke-linejoin: round;
}

elevation-profile .elevation.highlight {
  stroke-width: 3;
}

elevation-profile .area {
  fill: #e6e6e6;
  fill-opacity: 0.85;
}

/* hide the first label ('0 m') */
elevation-profile .axis.x .tick:first-of-type {
  display: none;
}

.point-orange {
  fill: #f39200;
}
.point-red {
  fill: #d4021c;
}

.dirty-svg:hover path {
  @apply fill-chm-focus-red;
}

.checkbox-container {
  @apply inline-flex items-center gap-x-[15px] cursor-pointer text-body md:text-md-body;
}

.left-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  direction: rtl;
}

.left-ellipsis span {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.show-menu {
  visibility: visible;
  opacity: 1;
}

.hide-menu {
  visibility: hidden;
  opacity: 0;
}

.search-filter {
  @apply bg-page-main text-black;
}

.search-filter-selected {
  @apply bg-black text-white;
}

.search-filter-disabled {
  @apply bg-chm-gray text-white disabled;
}
